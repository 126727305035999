<template>
  <div class="page page__content-wrapper">

    <div class="page__full-width">
      <page-item v-for="(item, idx) in equipments" :content="item" :key="idx"></page-item>
    </div>

    <ButtonDownload
      :textButton="download.text"
      :url="download.url"
      :icons="download.icons"
      class="page__download-block"
    />

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import PageItem from "@/components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    ButtonDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/presentations",
          text: "Презентации"
        },
        next: {
          url: "/brand/souvenir-products",
          text: "Сувенирная продукция"
        }
      },
      equipments: [
        {
          type: "slider",
          sliderType: "ImagesSlider",
          title: "Куртка",
          slides: [
            {
              imgSlide: "equipments-page/equipment-coat-01.png",
              isChecked: true
            },
            {
              imgSlide: "equipments-page/equipment-coat-02.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/08-Equipment/Steor-Coat.zip",
              icon: "icon-ai.svg"
            }
          ]
        },
        {
          type: "slider",
          sliderType: "ColoredSlider",
          title: "Кепка",
          text: "Предпочтительный способ нанесения логотипа — вышивка. Модель кепки должна быть без фронтального шва. Варианты цветов: светло-серый, темно-синий и светло-синий — близкие по оттенкам к фирменным.",
          slides: [
            {
              bgColor: "#D5DDE5",
              imgSlide: "equipments-page/equipment-cap-01.png",
              isChecked: true
            },
            {
              bgColor: "#041C36",
              imgSlide: "equipments-page/equipment-cap-02.png",
              isChecked: false
            },
            {
              bgColor: "#004CD1",
              imgSlide: "equipments-page/equipment-cap-03.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/08-Equipment/Steor-Cap.zip",
              icon: "icon-ai.svg"
            }
          ]
        },
        {
          type: "image",
          title: "Бейдж и лента",
          images: [
            {
              img: "equipments-page/equipment-badge.jpg",
              icons: [
                {
                  url: "../download-file/08-Equipment/Steor-Badge.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ],
        },
        {
          type: "image",
          title: "Наклейка на ноутбук",
          images: [
            {
              img: "equipments-page/equipment-sticker-01.png",
              icons: [
                {
                  url: "../download-file/08-Equipment/Steor-Sticker.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ],
        },
        {
          type: "image",
          title: "Наклейка на оборудование",
          images: [
            {
              img: "equipments-page/equipment-sticker-02.png",
              icons: [
                {
                  url: "../download-file/08-Equipment/Steor-HardwareSticker.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ],
        }
      ],
      download: {
        text: "Скачать все макеты",
        url: "08-Equipment/Steor-Equipment.zip"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;

  &__min-container {
    margin-bottom: 0;
  }

  &__link-block {
    margin-left: 23px;
  }

  /deep/.page-item {
    &:first-child {
      .slider__download-block {
        margin-top: -104px;
      }
    }
    &:last-child {
      margin-bottom: 95px;
      .image__download-block {
        margin-top: 112px;
      }
    }
  }

  ///deep/.download-block {
  //  margin-left: 24px;
  //}

  /deep/.slider__download-block {
    margin-top: -86px;
  }
}



@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 48px;
    margin-bottom: 190px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}

</style>
