<template>
  <!--  <a :href="`/download-file/${url}`">-->
  <div class="icon">
    <img
        :src="require(`@/assets/img/icons/download-icons/${icon}`)"
        alt=""
    />
  </div>
  <!--  </a>-->
</template>

<script>
export default {
  props: {
    // url: {
    //   type: String,
    //   required: true
    // },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #C0C6CC;
}

img {
  //width: 40px;
  //height: 40px;
}
</style>